import { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ImgPath } from "../../utils/contants";
import useNavLinks from "../Navigation.config";
import "./Header.scss";

enum ScrollDirection {
  UP = 'up',
  DOWN = 'down'
}

function Header() {
  const imgPath = ImgPath;
  const pathname = useLocation().pathname;
  const [toggleMenu, setToggleMenu] = useState(false);
  const [sticky, setSticky] = useState("");
  const [scrollDirection, setScrollDirection] = useState("");
  const [links] = useNavLinks();

  useEffect(() => {
    // Header
    let lastScrollY = window.pageYOffset;
    const isSticky = () => {
      /* Method that will fix header after a specific scrollable */
      const scrollTop = window.scrollY;
      const stickyClass = scrollTop >= 50 ? "sticky" : "";
      setSticky(stickyClass);

      if (scrollTop <= 400) {
        setScrollDirection(ScrollDirection.UP);
        return;
      }

      // update Scroll Direction
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? ScrollDirection.DOWN : ScrollDirection.UP;

      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    }

    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, [sticky, scrollDirection]);

  const renderNavLinks = () => {
    const mapRows = links.dchf.map((item, index) => (
      <Fragment key={index}>
          <li onClick={onCloseMenu} className={
            pathname === item.path ? 'active' : ''
          }><Link to={item.path}>{item.name}</Link></li>
      </Fragment>
    ));
    return mapRows;
  };
  
  const onToggleMenu = () => {
    let toggle = !toggleMenu;
    
    toggle
      ? document.body.classList.add('toggle-open')
      : document.body.classList.remove('toggle-open');

    setToggleMenu(() => toggle);
  };

  const onCloseMenu = () => {
    document.body.classList.remove('toggle-open');
    setToggleMenu(() => false);
  }

  return (
    <header className={`header ${sticky} ${scrollDirection}`}>
      <div className="dchf-container">
        <div className="header-wrap">
          <div className="header-logo">
            <Link to={"/"}>
              <img src={imgPath + "logos/logo-dchf-black.svg"} alt="logo" />
            </Link>
          </div>
          <div className={`header-menu ${toggleMenu ? 'is-open' : ''}`}>
            <ul>
              {renderNavLinks()}
              <li className="mobile">
                <a
                  className="button button-white"
                  data-bs-toggle="tooltip"
                  title="Coming Soon"
                >
                  Go to app
                </a>
              </li>
            </ul>
          </div>
          <div className="header-right">
            <div className="header-button">
              <a
                className="button button-black"
                data-bs-toggle="tooltip"
                title="Coming Soon"
              >
                Go to the App
              </a>
            </div>
            <button className={`main-menu-indicator ${toggleMenu ? 'is-open' : ''}`} onClick={onToggleMenu}>
              <span className="line"></span>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
