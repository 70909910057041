import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

interface ITooltip {
  children: any;
  placement: 'top' | 'right' | 'bottom' | 'left';
  title: string;
}

function MyTooltip({ children, placement, title }: ITooltip) {
  return (
    <>
      <OverlayTrigger
        key={placement}
        placement={placement}
        overlay={
          <Tooltip id={`tooltip-${placement}`}>
            {title}
          </Tooltip>
        }
      >
        {children}
      </OverlayTrigger>
    </>
  );
}

export default MyTooltip;
