import { useState } from "react";

export interface ILinks {
  path: string;
  name: string;
  soon?: boolean;
}

interface Pages {
  dchf: ILinks[];
  company: ILinks[];
  products: ILinks[];
  solutions: ILinks[];
}

const useNavLinks = () => {
  const [links] = useState<Pages>({
    dchf: [
      { path: '/', name: 'Discover' },
      { path: '/about', name: 'Why DCHF' },
      { path: '/works', name: 'How it works' },
    ],
    company: [
      { path: '/about-us', name: 'About Us', soon: true },
      { path: '/careers', name: 'Careers', soon: true },
      { path: '/contact-us', name: 'Contact Us', soon: true },
      { path: '/legal-terms', name: 'Legal Terms', soon: true },
      { path: '/cookie-settings', name: 'Cookie Settings', soon: true },
    ],
    products: [
      { path: '/dchf-app', name: 'DCHF App', soon: true },
      { path: '/moneta', name: 'Moneta' },
      { path: 'https://grizzly.fi/', name: 'Grizzly.fi' },
    ],
    solutions: [
      { path: '/for-individuals', name: 'For Individuals', soon: true },
      { path: '/for-merchants', name: 'For Merchants', soon: true },
      { path: '/for-exchanges', name: 'For Exchanges', soon: true },
      { path: '/for-developers', name: 'For Developers', soon: true },
    ]
  });

  return [links];
};

export default useNavLinks;