import React from 'react';
import {Route, Routes } from 'react-router-dom';

const Discover = React.lazy(() => import('./pages/discover/Discover'));
const Moneta = React.lazy(() => import('./pages/moneta/Moneta'));
const About = React.lazy(() => import('./pages/about/About'));
const Story = React.lazy(() => import('./pages/story/Story'));
const Works = React.lazy(() => import('./pages/works/Works'));
const Privacy = React.lazy(() => import('./pages/privacy/Privacy'));
const Imprint = React.lazy(() => import('./pages/imprint/Imprint'));

const AppRouter = () => {
  return (
    <Routes>
      <Route index element={
        <React.Suspense fallback={<AppLoadingFallback />}>
          <Discover />
        </React.Suspense>
      } />
      <Route path="/moneta" element={
        <React.Suspense fallback={<AppLoadingFallback />}>
          <Moneta />
        </React.Suspense>
      } />
      <Route path="/about" element={
        <React.Suspense fallback={<AppLoadingFallback />}>
          <About />
        </React.Suspense>
      } />
      <Route path="/story" element={
        <React.Suspense fallback={<AppLoadingFallback />}>
          <Story />
        </React.Suspense>
      } />
      <Route path="/works" element={
        <React.Suspense fallback={<AppLoadingFallback />}>
          <Works />
        </React.Suspense>
      } />
      <Route path="/privacy" element={
        <React.Suspense fallback={<AppLoadingFallback />}>
          <Privacy />
        </React.Suspense>
      } />
      <Route path="/imprint" element={
        <React.Suspense fallback={<AppLoadingFallback />}>
          <Imprint />
        </React.Suspense>
      } />
      <Route path="*" element={
        <React.Suspense fallback={<AppLoadingFallback />}>
          <Discover />
        </React.Suspense>
      } />
    </Routes>
  );
}

const AppLoadingFallback = () => <div className='app-loading'></div>;

export default AppRouter;
