import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import MyTooltip from "../../ui/tooltip/tooltip";
import { ImgPath } from "../../utils/contants";
import useNavLinks, { ILinks } from "../Navigation.config";
import "./Footer.scss";

function Footer() {
  const imgPath = ImgPath;
  const pathname = useLocation().pathname;

  const [navConfig] = useNavLinks();
  
  const renderNavLinks = (links: ILinks[]) => {
    const mapRows = links.map((item, index) => (
      item.soon ?
      <Fragment key={index}>
        <MyTooltip placement="top" title="Coming Soon">
          <li><Link to={'#'} >{item.name}</Link></li>
        </MyTooltip>
      </Fragment>
      :
      <Fragment key={index}>
          <li className={
            pathname === item.path ? 'active' : ''
          }>
            {item.path.includes('http')
              ? <a href={item.path} target="_blank" rel="noopener noreferrer">{item.name}</a>
              : <Link to={item.path}>{item.name}</Link>
            }
          </li>
      </Fragment>
    ));
    return mapRows;
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-10 col-xl-10">
            <div className="footer-top reveal">
              <div className="row justify-content-center">
                <div className="col-md-12 col-lg-12 col-xl-10">
                  <div
                    className="dchf-newsletter"
                    data-bs-toggle="tooltip"
                    title="Coming Soon"
                  >
                    <h2>Stay update. Subscribe now!</h2>
                    <div className="dchf-newsletter-input">
                      <input
                        type="email"
                        className="email"
                        placeholder="Enter Email"
                      />
                      <input
                        type="submit"
                        className="button send"
                        value="Sign up"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-center reveal">
              <div className="row">
                <div className="col-6 col-md-3 col-lg-3">
                  <div className="footer-menu">
                    <span>DCHF</span>
                    <ul>
                      {renderNavLinks(navConfig.dchf)}
                    </ul>
                  </div>
                </div>
                <div className="col-6 col-md-3 col-lg-3">
                  <div className="footer-menu">
                    <span>Company</span>
                    <ul>
                      {renderNavLinks(navConfig.company)}
                    </ul>
                  </div>
                </div>
                <div className="col-6 col-md-3 col-lg-3">
                  <div className="footer-menu">
                    <span>Products</span>
                    <ul>
                      {renderNavLinks(navConfig.products)}
                    </ul>
                  </div>
                </div>
                <div className="col-6 col-md-3 col-lg-3">
                  <div className="footer-menu">
                    <span>Solutions</span>
                    <ul>
                      {renderNavLinks(navConfig.solutions)}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="footer-bottom-wrap reveal">
                <div className="footer-bottom-left">
                  <div className="footer-logo">
                    <img src={imgPath + "logos/logo-dchf.svg"} alt="logo-dchf" />
                  </div>
                  <div className="footer-links">
                    <ul>
                      <li>
                        <a href="privacy">Privacy</a>
                      </li>
                      <li>
                        <a href="imprint">Imprint</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="footer-bottom-right">
                  <div className="footer-social">
                    <ul>
                      <li>
                        <a data-bs-toggle="tooltip" title="Coming Soon">
                          <svg className="icon-facebook">
                            <use href={imgPath + "icons.svg#icon-facebook"} />
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a data-bs-toggle="tooltip" title="Coming Soon">
                          <svg className="icon-linkedin">
                            <use href={imgPath + "icons.svg#icon-linkedin"} />
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a data-bs-toggle="tooltip" title="Coming Soon">
                          <svg className="icon-twitter">
                            <use href={imgPath + "icons.svg#icon-twitter"} />
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a data-bs-toggle="tooltip" title="Coming Soon">
                          <svg className="icon-youtube">
                            <use href={imgPath + "icons.svg#icon-youtube"} />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
